import React, { useState, useEffect } from 'react';
import { message, Spin, Progress } from 'antd';
import './CustomInputField.css';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Transaction, SystemProgram, PublicKey } from '@solana/web3.js';

const TokenSaleComponent = () => {
    const [tokenAmount, setTokenAmount] = useState(0);
    const [solAmount, setSolAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [transactionRejected, setTransactionRejected] = useState(false);
    const [confirmedTransactionMessage, setConfirmedTransactionMessage] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [userSolBalance, setUserSolBalance] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0);
    const goal = 375;
    const [insufficientBalanceError, setInsufficientBalanceError] = useState(false);
    const tokenPrice = 1;
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();

    const saleAddress = new PublicKey("84ZZYc1kjn2X45imZvbDLaRUUYUNsqitxugxePaZzevF");

    useEffect(() => {
        const fetchWalletBalance = async () => {
            try {
                const balance = await connection.getBalance(saleAddress);
                setWalletBalance(balance / 1e9); // Convert lamports to SOL
            } catch (error) {
                console.error("Failed to fetch wallet balance:", error);
            }
        };

        fetchWalletBalance();

        // Set an interval to update the balance every 10 seconds
        const interval = setInterval(fetchWalletBalance, 10000);

        return () => clearInterval(interval); // Cleanup interval on component unmount
        // eslint-disable-next-line
    }, [connection,]);

    const getRandomCanceledMessage = () => {
        const canceledMessages = [
            "Oops, Looks Like Someone Chickened Out!",
            "No Refunds, But Feel Free to Try Again!",
            "The User Hit the Panic Button. Try Again!",
            "Well, That Was Awkward. Try Again?",
            "Did You Mean to Cancel That? Oh Well...",
            "Guess You Didn't Want Those Tokens After All. Try Again!",
            "You Changed Your Mind? How Shocking!",
            "You Got Cold Feet. It Happens.",
            "Transaction? Canceled. Regret? Probably.",
            "You Stopped It, But It's Not Over Yet!"
        ];
        const randomIndex = Math.floor(Math.random() * canceledMessages.length);
        return canceledMessages[randomIndex];
    };

    const getRandomConfirmedMessage = () => {
        const confirmedMessages = [
            "Congrats! $TPG Is On Its Way (Maybe).",
            "You Will Receive $TPG... Eventually.",
            "$TPG is Coming to You, Whether You’re Ready or Not!",
            "Brace Yourself, $TPG Is About to Arrive!",
            "Hold Tight, $TPG is on Its Way (It’s Not Late, You’re Just Early).",
            "You Will Receive $TPG, Because We Like You (Sort of).",
            "Sit Back and Relax, $TPG Is on Its Way!",
            "You Will Receive $TPG... If the Blockchain Feels Like It.",
            "$TPG Is Coming Your Way, No Takebacks!",
            "Don’t Worry, $TPG is on Its Way to Make Your Day."
        ];
        const randomIndex = Math.floor(Math.random() * confirmedMessages.length);
        return confirmedMessages[randomIndex];
    };

    useEffect(() => {
        if (publicKey) {
            const fetchSolBalance = async () => {
                try {
                    const balance = await connection.getBalance(publicKey);
                    setUserSolBalance(balance / 1e9);
                } catch (error) {
                    console.error("Failed to fetch SOL balance:", error);
                    setUserSolBalance(0);
                }
            };
            fetchSolBalance();
        }
    }, [publicKey, connection]);

    const buyToken = async () => {
        if (!publicKey) {
            message.error('Please connect your wallet!');
            return;
        }
        if (tokenAmount === 0) {
            message.error('You must input $SOL amount.');
            return;
        }

        // Calculate the SOL amount to send
        const amountInSOL = Number(solAmount);

        // Check if the user has enough SOL balance
        if (amountInSOL > userSolBalance) {
            setInsufficientBalanceError(true);
            return;
        } else {
            setInsufficientBalanceError(false);
        }

        try {
            setLoading(true);

            // Fetch the latest blockhash
            const blockhashResponse = await connection.getLatestBlockhash('finalized');
            const blockhash = blockhashResponse.blockhash;
            console.log("recentBlockhash: ", blockhash);

            const lastValidBlockHeight = blockhashResponse.lastValidBlockHeight - 150;

            // Construct the transaction
            const transaction = new Transaction({
                feePayer: publicKey,
                blockhash: blockhashResponse.blockhash,
                lastValidBlockHeight: lastValidBlockHeight,
            }).add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: saleAddress,
                    lamports: amountInSOL * 1e9,
                })
            );
            console.log("Transaction prepared:", transaction);

            // Send the transaction and await signature
            const signature = await sendTransaction(transaction, connection, {
                skipPreflight: false,
                preflightCommitment: 'confirmed',
            });
            console.log(signature)

            // Confirm the transaction with appropriate commitment level
            await connection.confirmTransaction({
                signature,
                commitment: 'confirmed'
            });

            console.log("Transaction confirmed with signature:", signature);
            setTransactionId(signature);
            setConfirmedTransactionMessage(getRandomConfirmedMessage());
            message.success(`Transaction successful! ${tokenAmount} $TPG bought.`);
            setTransactionRejected(false);
        } catch (error) {
            if (error.message.includes("User rejected the request")) {
                console.warn("Transaction rejected by user.");
                message.error(getRandomCanceledMessage());
                setTransactionRejected(true);
            } else {
                console.error("Transaction failed:", error.message, error.stack);
                message.error("Transaction failed. Please check the console for more details.");
                setTransactionRejected(false);
            }
        } finally {
            setLoading(false);
        }
    };

    const progressPercentage = Math.min((walletBalance / goal) * 100, 100);

    return (
        <div className="col-lg-6 col-md-12 col-sm-12 order-lg-first">
            <div className="banner_text_s2 text_md_center animation" data-animation="fadeInUp" data-animation-delay="1.1s">
                <h2 className="animation text-white" data-animation="fadeInUp" data-animation-delay="1.1s">
                    <strong>TRUMPENGUIN</strong>
                </h2>
                <h5 className="animation presale_txt text-white pb-1" data-animation="fadeInUp" data-animation-delay="1.3s">
                    Don’t Miss Out, Token Presale Is <mark className="gradient_box">Live</mark>
                </h5>
                <div className="progress-container pb-0" >
                    <Progress
                        percent={progressPercentage}
                        status="active"
                        showInfo
                        format={(percent) => `${percent.toFixed(2)}% to DROP💧`}
                    />
                    <p className="text-white">Raised: {walletBalance.toFixed(2)} SOL / {goal} SOL</p>
                </div>
                <span className="drop-label pb-3">Wondering what you'll get in the future? Nothing less than an exclusive DROP from $TPG, which guarantees… well, nothing. But hey, at least you'll be able to say you supported a real leader, an emperor in a suit and tie.</span>
                <span className="custom-label pt-3">
                    {publicKey ? `Your SOL Balance: ${userSolBalance.toFixed(4)}` : 'Connect Your Wallet, Don’t Be Shy!'}
                </span>

                <div className="input-container">
                    <input
                        type="number"
                        required="required"
                        placeholder="How Much Are You Risking? *"
                        id="first-name"
                        className="form-control custom-input centered-input"
                        name="name"
                        onChange={(e) => {
                            const value = e.target.value;
                            setTokenAmount(Number(value));
                            setSolAmount((Number(value) * tokenPrice).toFixed(5));
                        }}
                    />
                </div>
                <div className="btn_group animation pt-3" data-animation="fadeInUp" data-animation-delay="1.4s">
                    {loading ? (
                        <>
                            <button onClick={buyToken} className="btn btn-border btn-radius" disabled={loading}>
                                Don't Worry, It's Totally Worth It... Probably.
                            </button>
                            <Spin tip="Processing..." className="custom-spin" /> {/* Spin with custom tip */}
                            <div className="custom-spin-tip">Processing transaction...</div> {/* Tip below the spinner */}
                        </>
                    ) : (
                        <button onClick={buyToken} className="btn btn-border btn-radius">
                            Do It, I Dare You!
                        </button>
                    )}
                    <div className="buy-button-legend">
                        <p className="text-white centered-text">Buy Button if you didn't notice</p>
                    </div>
                </div>
                <span className="text-white icon_title animation" data-animation="fadeInUp" data-animation-delay="1.4s">
                SOL is your VIP Pass. Or, you know, send USDT to 84ZZYc1kjn2X45imZvbDLaRUUYUNsqitxugxePaZzevF<br /> and see where fate takes you!
                </span>
                <span className="text-white icon_title animation" data-animation="fadeInUp" data-animation-delay="1.4s">
                If you're nervous, just retreat to the  
                <a href="https://www.pink.meme/solana/token/3h5HvxSxBJXQ9MjsUSTaK8B75rfPPuzzb32qc2QRpink" target="_blank" rel="noopener noreferrer"> PINK PINK </a>
                side, less scary, more cuddly!
                </span>
                {insufficientBalanceError && (
                    <div className="alert alert-danger" role="alert">
                        Insufficient SOL balance to complete the transaction.
                    </div>
                )}
                {transactionRejected && (
                    <div className="alert alert-danger" role="alert">
                        {getRandomCanceledMessage()} {/* Display the random canceled message */}
                    </div>
                )}
                {confirmedTransactionMessage && (
                    <div className="alert alert-success" role="alert">
                        {confirmedTransactionMessage} {/* Display the random confirmed message */}
                    </div>
                )}
                {transactionId && (
                    <div>
                        <p className="text-white">Transaction confirmed! 🎉</p>
                        <a
                            href={`https://explorer.solana.com/tx/${transactionId}?cluster=mainnet`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white"
                        >
                            View your tx on Solana Explorer
                        </a>
                    </div>
                )}
                <div id="whitepaper" className="team_pop mfp-hide">
                    <div className="row m-0">
                        <div className="col-md-7">
                            <div className="pt-3 pb-3">
                                <div className="title_dark title_border">
                                    <h4>Download Whitepaper</h4>
                                    <p>A purely peer-to-peer version of electronic cash would allow online payments to be sent directly from one party to another without going through a financial institution...</p>
                                    <a href="/" className="btn btn-radius btn-hover">Download Whitepaper</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TokenSaleComponent;
